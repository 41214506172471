import styled from 'styled-components';

export const Title = styled.div`
    max-width: 540px;
    height: 180px;
    font-family: Montserrat;
    font-size: 70px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #48c9d1;
    margin-bottom: 14px;
    margin-top: 78px;
`;
