import styled from 'styled-components'

export const PostDate = styled.div`
    font-family: Montserrat;
    color: #48c9d1;
    max-width: 160;
    max-height: 22px;
    opacity: 0.6;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 12px;
`
