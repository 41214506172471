import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { BlogContainer } from '../components/BlogContainer'
import Layout from '../components/Layout'
import { StyledLink } from '../components/Link'
import { PostDate } from '../components/PostDate'
import { PostTitle } from '../components/PostTitle'
import { Title } from '../components/Title'

const Excerpt = styled.p`
    font-family: Montserrat;
    color: #333333;
    max-width: 540px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
`

const BlogPost = styled.div`
    margin-bottom: 3em;
`

export default ({ data }) => (
    <Layout>
        <BlogContainer>
            <Helmet title="Blog" />
            <Title>Blog</Title>
            {data.allMarkdownRemark.edges.map(({ node }) => (
                <BlogPost key={node.fields.slug}>
                    <PostDate>{node.frontmatter.date}</PostDate>
                    <PostTitle>
                        <StyledLink to={node.fields.slug}>
                            {node.frontmatter.title}
                        </StyledLink>
                    </PostTitle>
                    <Excerpt
                        dangerouslySetInnerHTML={{ __html: node.excerpt }}
                    />
                </BlogPost>
            ))}
        </BlogContainer>
    </Layout>
)

export const query = graphql`
    query IndexQuery {
        allMarkdownRemark {
            totalCount
            edges {
                node {
                    frontmatter {
                        title
                        date(formatString: "D MMMM YYYY")
                    }
                    fields {
                        slug
                    }
                    excerpt
                }
            }
        }
    }
`
