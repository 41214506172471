import styled from 'styled-components'

export const PostTitle = styled.small`
    font-family: Montserrat;
    color: #48c9d1;
    height: 40px;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
`
